<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="用户姓名/手机号/id" class="width300"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
          <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" class="marginLeft10" icon="el-icon-download" @click="handleExport">导出</el-button>
        </div>
        <div class="flex align-items flex-wrap marginBottom20">
          <div class="marginRight20 count">可用蜜豆积分: {{ countData.useHoney }}</div>
          <div class="marginRight20 count">冻结蜜豆积分: {{ countData.frozenHoney }}</div>
          <div class="marginRight20 count">提现冻结蜜豆积分: {{ countData.withdrawFrozenHoney }}</div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleSet(row)">设置比例</el-button>
        <el-button size="mini" type="text" icon="el-icon-setting" v-if="userInfo.id === '618d882454d3b5f1e0d7373c78ac31ac'" @click="handleConfig(row)">配置蜜豆</el-button>
      </template>
    </avue-crud>

    <el-dialog
        title="设置比例"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
<!--        <template slot="frozenScaleMonth">-->
<!--          <el-input v-model="form.frozenScaleMonth"-->
<!--                    placeholder="请输入 每月冻结比例"-->
<!--                    @blur="changeBlur($event,'frozenScaleMonth',100)"-->
<!--                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">-->
<!--            <template slot="append">%</template>-->
<!--          </el-input>-->
<!--        </template>-->
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>


    <points v-if="show" :show="show" :info="info" @close="handlePointsClose"/>
  </div>
</template>

<script>
import {list, update, count} from "@/api/honey/user"
import points from "@/components/points/index.vue"
import {mapGetters} from "vuex";
export default {
  name: "index",
  components: {
    points
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data(){
    return{
      searchData: {
        name: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'蜜豆用户管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户id',
            prop: 'code'
          },
          {
            label: '用户姓名',
            prop: 'authUsername'
          },
          {
            label: '注册时间',
            prop: 'createTime'
          },
          {
            label: '可用蜜豆积分',
            prop: 'useHoney'
          },
          {
            label: '冻结蜜豆积分',
            prop: 'frozenHoney'
          },
          {
            label: '提现冻结蜜豆积分',
            prop: 'withdrawFrozenHoney'
          },
          {
            label: '每月解冻比例',
            prop: 'frozenScaleMonth',
            formatter: function(row, column, cellValue){
              // console.log(row, column, cellValue)
              return `${cellValue}%`
            }
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        frozenScaleMonth: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '每月解冻比例',
            prop: 'frozenScaleMonth',
            type: 'number',
            minRows: 1,
            maxRows: 100,
            controls: false,
            span: 24,
            rules: [{
              required: true,
              message: "请输入每月解冻比例",
              trigger: "blur"
            }]
          }
        ]
      },

      show: false,
      info: {
        id: '',
        max: 0,
        type: 1,
      },

      countData: {
        useHoney: 0, // 可用蜜豆积分
        frozenHoney: 0, // 	冻结蜜豆积分
        withdrawFrozenHoney: 0 // 	提现冻结蜜豆积分
      }
    }
  },
  mounted() {
    this.getCount()
  },
  methods: {
    getCount(){
      count().then(res => {
        if (res.code === 200) {
          this.countData = res.data
        }
      })
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res=>{
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 查询
    handleSearch(){
      this.showLoading = true;
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      this.searchData = {
        name: ''
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 设置比例
    handleSet(row){
      console.log('设置比例',row)
      this.dialogVisible = true;
      this.form.id = row.id;
      this.form.frozenScaleMonth = row.frozenScaleMonth;
    },
    // 导出
    handleExport(){
      this.$confirm('确定导出数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = `/api/web/honey/user/export?name=${this.searchData.name}`;
        let date = new Date();
        let time = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds();
        this.global.exportExcel(url, `蜜豆用户管理-${time}.xlsx`);
      })
    },
    // 提交
    submit(form,done){
      // console.log('提交',form)
      update({
        id: form.id,
        frozenScaleMonth: form.frozenScaleMonth
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('设置成功');
          this.dialogVisible = false;
          this.onLoad();
          this.resetForm();
        }else{
          this.$message.error(res.msg);
          done();
        }
      }).catch(()=> done())
    },
    // 关闭弹框
    handleClose(){
      this.dialogVisible = false;
      this.resetForm();
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        frozenScaleMonth: ''
      }
    },
    // 限制输入框
    changeBlur(e,text,max){
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            this.form[text] = Number(max).toFixed(2)
          }else{
            this.form[text] = Number(value).toFixed(2)
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },


    // 配置蜜豆
    handleConfig(row){
      // console.log('配置蜜豆',row)
      this.show = true;
      this.info = {
        id: row.id,
        max: row.useHoney,
        type: 1,
      }
    },
    handlePointsClose(e){
      this.show = false;
      this.info = {
        id: '',
        max: 0,
        type: 1
      }
      e && this.onLoad();
    }
  }
}
</script>

<style scoped>
.count{
  font-size: 14px;
  color: #606266;
}
</style>